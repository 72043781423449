import React, { useEffect } from "react";
import "./sectorPage.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useDispatch } from "react-redux";
import { setNavColor } from "../../redux/actions";

//SEO
import { Helmet } from "react-helmet";

const SectorPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    Aos.init({ duration: 1000 });
    dispatch(setNavColor(3));
  });

  return (
    <div className="sectorContainer">
      <Helmet>
        <title>Choose your domain</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Choose your domain, whether it is a service, manufacturing, media & advertising, jewellery & dimonds, retail, logistics we will get you covered"
        />
        <meta
          name="keywords"
          content="microsoft dynamics 365, management reporting, power bi, web development, web dev, android development, native development, application development, application, hybrid development, hybrid, microsoft, dynamics, ax, 365, 2012, 2009, web, development, dev, app, native, android, flutter, react, reactjs, reactnative, power, bi, powerbi, management, reporting, sector, choose your domain, your, domain, service sector, manufacturing sector, media and advertising, jewellery, &, dimonds, Retail, logistics"
        />
      </Helmet>
      <div className="sectorHead">
        <h1>Dynamics 365 F&O as a solution for your Sector</h1>
      </div>
      <div data-aos="fade-right" className="content">
        <div className="contentSections">
          <h2>Service Sector</h2>
          <div>
            Dynamics 365 F&O is suitable for all kinds of services from Software
            development to Research analysis. The service Item concept helps
            track, account, and report all services a company needs. Apart from
            it, various modules help to emphasize processes like projects or
            Capitalise a research service using a Fixed asset module.
          </div>
        </div>
        <div data-aos="fade-left" className="contentSections">
          <h2>Manufacturing Sector</h2>
          <div>
            Lean-to Discrete, MTO to MTS, Project to Job all types of
            manufacturing processes can be handled in 365 F&O. Quality
            management, Product mix, Lead time, MRP, etc all the details needed
            for manufacturing is captured in 365 F&O for the process, accounting
            & reporting.
          </div>
        </div>

        <div data-aos="fade-right" className="contentSections">
          <h2>Media & Advertising</h2>
          <div>
            The flexibility of 365 F&O to integrate with almost every software
            we came across has given us success in implementing them in unique
            industries like media & advertising. Global reporting and
            consolidation have resulted in efficient management & reporting in
            this industry.
          </div>
        </div>

        <div data-aos="fade-left" className="contentSections">
          <h2>Jewellery & Diamonds</h2>
          <div>
            The inventory valuation accuracy & scrap accounting feature of 365
            F&O also is sharp and fits the Jewellery industry. A better
            reporting of normal/abnormal loss results in appropriate accounting
            and valuation which is a key area in this sector.
          </div>
        </div>

        <div data-aos="fade-right" className="contentSections">
          <h2>Retail Sector</h2>
          <div>
            Product information management and Retail modules in 365 F&O are
            well equipped to handle any level of Retail load. Brick & Mortar &
            Online model to business are handled with POS setups and thus are
            fit to the industry handling terminals sales and returns.
          </div>
        </div>

        <div data-aos="fade-left" className="contentSections">
          <h2>Logistics Sector</h2>
          <div>
            Logistics industries with their industry-specific software tend to
            integrate the systems to use the other enhanced features of 365 F&O
            like fixed asset management, budgeting, and also Project accounting
            which results in accounting and reporting on time.
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectorPage;
