import {
  CHANGE_NAV_COLOR,
  CHANGE_setScrollToServicesSection,
  CHANGE_setScrollToProductsSection,
} from "./constants.js";
export const setNavColor = (number) => ({
  type: CHANGE_NAV_COLOR,
  payload: number,
});
export const setScrollToServicesSection = (number) => ({
  type: CHANGE_setScrollToServicesSection,
  payload: number,
});
export const setScrollToProductsSection = (number) => ({
  type: CHANGE_setScrollToProductsSection,
  payload: number,
});
