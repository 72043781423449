import React from "react";
import "./footer.css";
import ManesLogo from "../../graphics/Logo (1).svg";
//redux
import { useDispatch } from "react-redux";
//icons
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

//components
import CustButton from "../button/custButton.js";
import { Link } from "react-router-dom";
import {
  setScrollToProductsSection,
  setScrollToServicesSection,
} from "../../redux/actions";
const Footer = ({ title, content, image, reverse }) => {
  const dispatch = useDispatch();
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="footerContainer">
      <div className="footerContent">
        <div className="footerSection footerAbout">
          <div className="footerLogo">
            <div>
              <h2>Mane Services</h2>
            </div>
            <div>
              We provide services to help you run your business with ease and
              boost your productivity.
            </div>
            <div className="contact">
              <span>
                <i className="footerSmallIcon">
                  <FaPhone />
                  &nbsp;
                </i>
                +917021070199
              </span>
              <span>
                <i className="footerSmallIcon">
                  <FaEnvelope />
                  &nbsp; support@manes.in
                </i>
              </span>
              <span>
                <i className="footerSmallIcon">
                  <FaMapMarkerAlt />
                  &nbsp; 36, Ameya, Shreesh CHS, Hajuri Road, Thane W. 400604 MH
                </i>
              </span>
            </div>
          </div>
        </div>
        <div className="footerSection footerLinks">
          <h2>Quick Links</h2>
          <ul>
            <Link to="/" onClick={scrollToTop}>
              <a herf="#">
                <li>Home</li>
              </a>
            </Link>
            <Link
              onClick={() => {
                dispatch(setScrollToProductsSection(1));
              }}
              to="/#products&SolutionsSection"
            >
              <a herf="#">
                <li>Products & Solutions</li>
              </a>
            </Link>
            <Link to="/whyChooseUs" onClick={scrollToTop}>
              <a herf="/whyChooseUs">
                <li>Why choose us</li>
              </a>
            </Link>

            <Link to="/ourServices" onClick={scrollToTop}>
              <a herf="/#ourServices">
                <li>Our services</li>
              </a>
            </Link>
            <Link to="/ChooseYourDomain" onClick={scrollToTop}>
              <a herf="/#ChooseYourDomain">
                <li>ChooseYourDomain</li>
              </a>
            </Link>
          </ul>
        </div>
        <div className="footerSection footerContact">
          <h2>Get in touch</h2>
          <Link to="/contactUs" onClick={scrollToTop}>
            {" "}
            <CustButton text="Contact us" size={"1.2em"} />
          </Link>
        </div>
      </div>
      <div className="footerBottom">&copy;Mane Services</div>
    </div>
  );
};

export default Footer;
