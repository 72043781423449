import React, { useState } from "react";
import "./smallCard.css";
import Implementation from "../../graphics/undraw_Work_time_re_hdyv.svg";

//Animation
import ScrollAnimation from "react-animate-on-scroll";

const SmallCard = ({ title, content, image, url }) => {
  const [isHovered, setIsHovered] = useState(false);
  const hovered = () => {
    setIsHovered(true);
  };
  const hoverExit = () => {
    setIsHovered(false);
  };
  return (
    <div
      onClick={() => {
        window.open(url);
      }}
      onMouseEnter={hovered}
      onMouseLeave={hoverExit}
      className="smallCardContainer"
    >
      <div className="cardContent">
        <ul>
          <li>
            <img
              id="smallCardImg"
              className={
                isHovered ? "invertSmallCardImg" : "defaultsmallCardImg"
              }
              src={image}
            ></img>
          </li>
          <li>
            <div className="cardContentText">
              <div>{title}</div>
              <div id="crdText">{content}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SmallCard;
