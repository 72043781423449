import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./navBar.css";

import CustButton from "../../components/button/custButton.js";
//icons
import { FaTimes, FaBars } from "react-icons/fa";
import { useLocation } from "react-router-dom";

//redux
//redux
import {
  setNavColor,
  setScrollToServicesSection,
  setScrollToProductsSection,
} from "../../redux/actions.js";
import { connect, useDispatch } from "react-redux";

//react scroll
import { Link as ReactScrollLink } from "react-scroll";

function useOnScreen() {}
const NavBar = ({ navBgColor }) => {
  const currentLocation = useLocation();
  const dispatch = useDispatch();

  let loc = currentLocation.pathname;
  console.log("currentLocation: ", currentLocation.pathname);
  const [click, setClick] = useState(false);
  const clickHandler = () => {
    setClick(!click);
    console.log("clicked!");
    navLinks.forEach((link, index) => {
      if (link.style.animation) link.style.animation = ``;
      else
        link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7}s`;
      console.log(link.classList);
    });
    const burger = document.querySelector(".burger");
    const navBar = document.querySelector(".navbar");

    navBar.classList.toggle("navMobActive");
    if (navBar.classList.contains("navMobActive")) {
      burger.classList.add("toggle");
    } else {
      burger.classList.remove("toggle");
    }
  };
  const navLinks = document.querySelectorAll(".navUl li");
  const closeMobileMenu = () => {
    setClick(false);
    const burger = document.querySelector(".burger");
    const navBar = document.querySelector(".navbar");

    navBar.classList.toggle("navMobActive");
    if (navBar.classList.contains("navMobActive")) {
      burger.classList.add("toggle");
    } else {
      burger.classList.remove("toggle");
    }
    dispatch(setNavColor(3));

    window.scrollTo(0, 0);
  };
  const navigateToServicePage = () => {
    closeMobileMenu();
    console.log("navigateToSec");
    dispatch(setScrollToServicesSection(1));
  };
  const navigateToProductsPage = () => {
    closeMobileMenu();
    console.log("navigateToSec");
    dispatch(setScrollToProductsSection(1));
  };

  return (
    <nav
      className={
        navBgColor === 1
          ? "navbar"
          : navBgColor === 2
          ? "navbar navScrolledBlack"
          : "navbar navScrolledWhite"
      }
    >
      <div className={navBgColor === 3 ? "logo blackNavText" : "logo"}>
        <div>
          {" "}
          <img
            alt="Mane Services logo"
            class={navBgColor === 3 ? "navBarLogo navBlackLogo" : "navBarLogo"}
            src={require("../../graphics/ManeLogo.svg")}
          />
        </div>

        <div>Mane Services</div>
      </div>
      <ul
        className={
          click
            ? navBgColor === 3
              ? "navUl nav-active whiteBurgerBg"
              : "navUl nav-active"
            : navBgColor === 3
            ? "navUl whiteBurgerBg"
            : "navUl"
        }
      >
        <li>
          <Link
            to="/"
            className={
              navBgColor === 3
                ? loc === "/"
                  ? "nav-links blackNavLinks activateLink"
                  : " blackNavLinks nav-links"
                : loc === "/"
                ? "nav-links activateLink"
                : "nav-links"
            }
            onClick={closeMobileMenu}
          >
            Home
          </Link>
        </li>
        <li>
          {loc === "/" ? (
            <ReactScrollLink
              smooth="true"
              duration={1000}
              to="products&SolutionsSection"
              className={
                navBgColor === 3
                  ? loc === "/#products&SolutionsSection"
                    ? "nav-links blackNavLinks activateLink"
                    : " blackNavLinks nav-links"
                  : loc === "/#products&SolutionsSection"
                  ? "nav-links activateLink"
                  : "nav-links"
              }
              onClick={closeMobileMenu}
            >
              Products & Solutions
            </ReactScrollLink>
          ) : (
            <Link
              to="/#products&SolutionsSection"
              className={
                navBgColor === 3
                  ? loc === "/#products&SolutionsSection"
                    ? "nav-links blackNavLinks activateLink"
                    : " blackNavLinks nav-links"
                  : loc === "/#products&SolutionsSection"
                  ? "nav-links activateLink"
                  : "nav-links"
              }
              onClick={navigateToProductsPage}
            >
              Products & Solutions
            </Link>
          )}
        </li>
        <li>
          <Link
            to="/whyChooseUs"
            className={
              navBgColor === 3
                ? loc === "/whyChooseUs"
                  ? "nav-links blackNavLinks activateLink"
                  : " blackNavLinks nav-links"
                : loc === "/whyChooseUs"
                ? "nav-links activateLink"
                : "nav-links"
            }
            onClick={closeMobileMenu}
          >
            Why choose us
          </Link>
        </li>

        <li>
          <Link
            to="/ourServices"
            className={
              navBgColor === 3
                ? loc === "/ourServices"
                  ? "nav-links blackNavLinks activateLink"
                  : " blackNavLinks nav-links"
                : loc === "/ourServices"
                ? "nav-links activateLink"
                : "nav-links"
            }
            onClick={closeMobileMenu}
          >
            Our services
          </Link>
        </li>
        <li>
          <Link
            to="/ChooseYourDomain"
            className={
              navBgColor === 3
                ? loc === "/ChooseYourDomain"
                  ? "nav-links blackNavLinks activateLink"
                  : " blackNavLinks nav-links"
                : loc === "/ChooseYourDomain"
                ? "nav-links activateLink"
                : "nav-links"
            }
            onClick={closeMobileMenu}
          >
            Choose your domain
          </Link>
        </li>
        <li>
          <Link to="/contactUs" onClick={closeMobileMenu}>
            <div className="navContactUsButton navBttn">Contact us</div>
          </Link>
        </li>
      </ul>
      <div
        className={navBgColor === 3 ? "burger blackBurger" : "burger"}
        onClick={clickHandler}
      >
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
  );
};

export default NavBar;
