import React from "react";
import "./cardView.css";
import Implementation from "../../graphics/undraw_Work_time_re_hdyv.svg";
const CardView = ({ title, content, image, reverse }) => {
  return (
    <div className="CardContainer">
      <div id="cardViewDivider"></div>
      <div className="contentCard">
        <ul className={reverse ? "reverse" : ""}>
          <li>
            <div className="contentCardText">
              <div>{title}</div>
              <div id="cardText">{content}</div>
            </div>
          </li>
          <li>
            <img alt={title + " image"} src={image}></img>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CardView;
