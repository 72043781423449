import {
  CHANGE_NAV_COLOR,
  CHANGE_setScrollToServicesSection,
  CHANGE_setScrollToProductsSection,
} from "./constants.js";

const initialState = {
  navColorNumber: 1,
  scrollToServicesSection: 0,
  scrollToProductsSection: 0,
};
export const NavColorStateReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CHANGE_NAV_COLOR:
      return Object.assign({}, state, { navColorNumber: action.payload });
    case CHANGE_setScrollToServicesSection:
      return Object.assign({}, state, {
        scrollToServicesSection: action.payload,
      });
    case CHANGE_setScrollToProductsSection:
      return Object.assign({}, state, {
        scrollToProductsSection: action.payload,
      });
    default:
      return state;
  }
};
