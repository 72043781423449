import React, { useState } from "react";
import "./custButton.css";

//Animation
import ScrollAnimation from "react-animate-on-scroll";

const CustButton = ({ text, onPress, size }) => {
  return (
    <div
      className="custButton custBttn"
      onClick={onPress}
      style={{ fontSize: size ? size : 30 }}
    >
      {text}
    </div>
  );
};

export default CustButton;
