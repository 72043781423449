import React, { useEffect } from "react";
import "./servicesPage.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useDispatch } from "react-redux";
import { setNavColor } from "../../redux/actions";
import CardView from "../../components/cardView/cardView";
//images
import Implementation from "../../graphics/undraw_Work_time_re_hdyv.svg";
import Integration from "../../graphics/undraw_usability_testing_2xs4.svg";
import Support from "../../graphics/support.svg";
import Solution from "../../graphics/solution.svg";
import Outsource from "../../graphics/outsource.svg";
import ProjectManagement from "../../graphics/projectManagement.svg";
import DynamicsLogo from "../../graphics/dynamics365Logo.svg";
import PhoneDLogo from "../../graphics/phoneSvgDynamics.svg";
import PhoneLogo from "../../graphics/phoneApplication.svg";
import ManagementLogo from "../../graphics/manage.svg";
import PowerBI from "../../graphics/powerbiLogo.svg";
import Globe from "../../graphics/webDev.svg";
import Flutter from "../../graphics/flutter.svg";
import ReactNative from "../../graphics/reactLogo.svg";

//SEO
import { Helmet } from "react-helmet";

const SectorPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    Aos.init({ duration: 1000 });
    dispatch(setNavColor(3));
  });

  return (
    <div className="secondScreen">
      <Helmet>
        <title>Services we offer</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Wanna work with us?, check out what services we offer"
        />
        <meta
          name="keywords"
          content="microsoft dynamics 365, management reporting, power bi, web development, web dev, android development, native development, application development, application, hybrid development, hybrid, microsoft, dynamics, ax, 365, 2012, 2009, web, development, dev, app, native, android, flutter, react, reactjs, reactnative, power, bi, powerbi, management, reporting, services we offer, services, services we provide, provide, implementation, integration, support consulting, solution architect, project management, outsourcing"
        />
      </Helmet>
      <div className="secondScreenHeads">
        <h1 data-aos="fade-in">Services we offer</h1>
        <p data-aos="fade-in">We help you run your business with ease</p>
      </div>
      <div className="contentContainer">
        <ul>
          <li data-aos="fade-right">
            <CardView
              title="Implementation"
              content='Complete expertise in "Demo to Deploy" process until stabilization via post Go-live support. Professional documentation on standard and customized functionalities.'
              image={Implementation}
              reverse={false}
            />
          </li>
          <li data-aos="fade-left">
            <CardView
              title="Integration"
              content="Experienced approach in establishing a fully integrated ERP system appreciating the utility and flexibility of 3rd party software."
              image={Integration}
              reverse={true}
            />
          </li>
          <li data-aos="fade-right">
            <CardView
              title="Support Consulting"
              content="All-round support builds on SLAs and Cost-effective model to prioritize and expedite delivery. Training and Improvisation to make clients self-sufficient."
              image={Support}
              reverse={false}
            />
          </li>
          <li data-aos="fade-left">
            <CardView
              title="Solution Architect"
              content="Vast knowledge and experience results in better solutions.10+ yrs of experience have resulted in a better product and process understanding."
              image={Solution}
              reverse={true}
            />
          </li>

          <li data-aos="fade-right">
            <CardView
              title="Project Management"
              content="We are equipped to foresee challenges even before they arise in a project due to our vast project experience across business, technologies & user base. We apply best practice methodologies with a mix of situation-based approaches to achieve a higher success rate in ERP Implementations."
              image={ProjectManagement}
              reverse={false}
            />
          </li>

          <li data-aos="fade-left">
            <CardView
              title="Outsourcing"
              content="Accounting to Finalisation TDS Returns GST Returns For Individuals, Firms, Corporates, and Societies, etc with complete bookkeeping facility."
              image={Outsource}
              reverse={true}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SectorPage;
