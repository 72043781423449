import React, { Component, useEffect, useRef } from "react";
import "./homePage.css";

import { Link } from "react-router-dom";

import Slider from "react-slick";

//logo
import Logo from "../../graphics/Logo (1).svg";
import ScrollDownGif from "../../graphics/scroll.gif";
import BuisnessAnalytics from "../../graphics/buisnessAnalytics.svg";
//images
import Implementation from "../../graphics/undraw_Work_time_re_hdyv.svg";
import Integration from "../../graphics/undraw_usability_testing_2xs4.svg";
import Support from "../../graphics/support.svg";
import Solution from "../../graphics/solution.svg";
import Outsource from "../../graphics/outsource.svg";
import ProjectManagement from "../../graphics/projectManagement.svg";
import DynamicsLogo from "../../graphics/dynamics365Logo.svg";
import PhoneDLogo from "../../graphics/phoneSvgDynamics.svg";
import PhoneLogo from "../../graphics/phoneApplication.svg";
import ManagementLogo from "../../graphics/manage.svg";
import PowerBI from "../../graphics/powerbiLogo.svg";
import Globe from "../../graphics/webDev.svg";
import Flutter from "../../graphics/flutter.svg";
import ReactNative from "../../graphics/reactLogo.svg";

import Aos from "aos";
import "aos/dist/aos.css";
//intersection observer
import { useIntersection } from "react-use";

//redux
import {
  setNavColor,
  setScrollToProductsSection,
  setScrollToServicesSection,
} from "../../redux/actions.js";
import { connect, useDispatch, useSelector } from "react-redux";

//SEO
import { Helmet } from "react-helmet";

//components

import SmallCard from "../../components/smallCard/smallCard.js";
import { FaAppStore } from "react-icons/fa";
import CustButton from "../../components/button/custButton.js";

const HomePage = () => {
  const scrollToServicesSectionState = useSelector(
    (state) => state.scrollToServicesSection
  );
  const scrollToProductsSectionState = useSelector(
    (state) => state.scrollToProductsSection
  );
  useEffect(() => {
    Aos.init({ duration: 1000 });

    if (scrollToServicesSectionState === 1) {
      document.getElementById("sectionServices").scrollIntoView({
        behavior: "smooth",
      });
      dispatch(setScrollToServicesSection(0));
    }

    if (scrollToProductsSectionState === 1) {
      document.getElementById("products&SolutionsSection").scrollIntoView({
        behavior: "smooth",
      });
      dispatch(setScrollToProductsSection(0));
    }
  });

  var settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    className: "slides",
  };

  const handleCheck = (e) => {
    let elements = document.getElementsByClassName("WhatWeDoLi");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("WhatWeDoMenuUnderline");
    }
    let element = e.currentTarget;
    element.className = "WhatWeDoLi WhatWeDoMenuUnderline";
  };
  const dispatch = useDispatch();
  const ref = { current: null };

  const sectionRef = useRef(null);
  const ThirdRef = useRef(null);

  const intersectionFirst = useIntersection(sectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  const intersectionSecond = useIntersection(sectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.8,
  });

  const intersectionThird = useIntersection(ThirdRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.8,
  });
  if (intersectionFirst)
    console.log("intersection1 ratio: ", intersectionFirst.intersectionRatio);
  if (intersectionSecond)
    console.log("intersection2 ratio: ", intersectionSecond.intersectionRatio);

  if (intersectionFirst && intersectionFirst.intersectionRatio === 1) {
    console.log("First reached#######");
    dispatch(setNavColor(1));
  } else dispatch(setNavColor(3));

  return (
    <div>
      {/*SEO optimization*/}
      <Helmet>
        <title>Mane Services</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="We provide services to help you run your business with ease and
              boost your productivity"
        />
        <meta
          name="keywords"
          content="microsoft dynamics 365,management reporting,power bi,web development,web dev, android development,native development,application development, application, hybrid development, hybrid, microsoft,dynamics,ax,365,2012,2009,web,development,dev,app,native,android,flutter,react,reactjs,reactnative,power,bi,powerbi,management,reporting"
        />
      </Helmet>
      {/*SEO------*/}

      <div className="logoCont startPg">
        <div ref={sectionRef} className="logoImg">
          <img alt="Manes Logo" id="svgManeSLogo" src={Logo}></img>
          <div id="subtitle">Process analyst & Upgrade catalyst</div>
          <div id="knowMoreBttn">
            <Link to="/whyChooseUs">
              <CustButton text="Know more" />
            </Link>
          </div>
          <div className="scrollDownGif">
            <img alt="Scroll DOwn" src={ScrollDownGif}></img>
          </div>
        </div>
      </div>

      <div id={"products&SolutionsSection"} className="servicesWeProvide">
        <div>
          <h1 data-aos="fade-in">Products & solutions</h1>
        </div>
        <div class="column">
          <div data-aos="flip-right">
            <SmallCard
              url="https://dynamics.microsoft.com/en-in/"
              image={DynamicsLogo}
              title="Microsoft Dynamics 365"
              content={
                <div>
                  We help you take care of your business needs using{" "}
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(
                        "https://docs.microsoft.com/en-us/dynamics365/fin-ops-core/dev-itpro/migration-upgrade/versions-update-policy#dates-and-versions-for-finance-and-operations-application-and-platform-releases"
                      );
                    }}
                    style={{ color: "black" }}
                  >
                    Microsoft Dynamics 365 for Finance and Operations
                  </span>
                  {""},{" "}
                  <span
                    style={{ color: "black" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(
                        "https://docs.microsoft.com/en-us/dynamicsax-2012/appuser-itpro/what-s-new-in-microsoft-dynamics-ax-2012-r3?redirectedfrom=MSDN"
                      );
                    }}
                  >
                    AX 2012
                  </span>
                  {""},{" "}
                  <span
                    style={{ color: "black" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(
                        "https://mbs.microsoft.com/customersource/Global/AX/version/AX2009"
                      );
                    }}
                  >
                    AX 2009
                  </span>{" "}
                  and{" "}
                  <span
                    style={{ color: "black" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(
                        "https://docs.microsoft.com/en-us/dynamicsax-2012/appuser-itpro/point-of-sale"
                      );
                    }}
                  >
                    Microsoft Dynamics AX for Retail and Commerce (POS)
                  </span>
                </div>
              }
            />
          </div>
          <div data-aos="flip-left">
            <SmallCard
              url="https://community.dynamics.com/ax/b/axinthefield/"
              image={ManagementLogo}
              title="Management Reporting/Power BI"
              content={
                "Worried about financial reporting and business analysis? Contact us and we will take care of it for you"
              }
            />
          </div>
          <div data-aos="flip-right">
            <SmallCard
              url="https://docs.microsoft.com/en-us/dynamics365/fin-ops-core/dev-itpro/mobile-apps/Mobile-app-home-page"
              image={PhoneDLogo}
              title="Mobile Apps for Dynamics 365"
              content="Wanna integrate Dynamics 365 in your mobile application? Leave it on us, we will get it done for you"
            />
          </div>
          <div data-aos="flip-left">
            <SmallCard
              url="https://en.wikipedia.org/wiki/Web_development"
              image={Globe}
              title="Web application development"
              content="Whether it is a simple single static page of plain text or a fully responsive website with database integration, we've got you covered"
            />
          </div>
          <div data-aos="flip-right">
            <SmallCard
              url="https://developer.android.com/studio/intro"
              image={PhoneLogo}
              title="Native android application development"
              content="Take your business  next level with our beautifully designed responsive native android applications"
            />
          </div>
          <div data-aos="flip-left">
            <SmallCard
              url="https://flutter.dev/"
              image={Flutter}
              title="Hybrid application development in Flutter"
              content="Do you need a cross-platform application with minimum development overheads? get in touch with us, we will develop your dream application using flutter"
            />
          </div>
          <div data-aos="flip-right">
            <SmallCard
              url="https://reactnative.dev/"
              image={ReactNative}
              title="Cross-platform application development in React Native"
              content="If you need a cross-platform application which is neither native nor hybrid then don't worry, we will take care of your needs with React native"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
