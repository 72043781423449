import React, { Component } from "react";

import "./App.css";
import NavBar from "./components/navBar/navBar";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//pages
import HomePage from "./pages/homePage/homePage";
import WhyChooseUs from "./pages/whyChooseUs/whyChooseUs.js";
import SectorPage from "./pages/sectorPage/sectorPage.js";
import Services from "./pages/servicesPage/servicesPage.js";
//redux
import { connect } from "react-redux";
import { setNavColor } from "./redux/actions.js";

//components
import Footer from "./components/footer/footer.js";
import ContactUs from "./pages/contactUs/contactUs";
import { FaServicestack } from "react-icons/fa";
class App extends Component {
  constructor() {
    super();
  }
  componentDidMount() {}
  render() {
    return (
      <div className="App">
        <Router>
          <NavBar navBgColor={this.props.navColorNumber} />
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/whyChooseUs" exact component={WhyChooseUs} />
            <Route path="/ourServices" exact component={Services} />
            <Route path="/ChooseYourDomain" exact component={SectorPage} />
            <Route path="/contactUs" exact component={ContactUs} />
          </Switch>
          <Footer />
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    navColorNumber: state.navColorNumber,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setNavColor: () => dispatch(setNavColor()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
