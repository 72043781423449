import React, { useEffect } from "react";
import "./whyChooseUs.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useDispatch } from "react-redux";
import { setNavColor } from "../../redux/actions";

//SEO
import { Helmet } from "react-helmet";

const WhyChooseUs = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    Aos.init({ duration: 1000 });
    dispatch(setNavColor(3));
  });

  return (
    <div className="whyChooseUsContainer">
      <Helmet>
        <title>Services we offer</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Why us? We are certified professionals with expertise in Research, Design, Development, and Delivery of high-end Technology Solutions has achieved the toughest of implementations in IT Services. Our core competency, developed through extensive product wide exposure to a variety of business processes, industries, and technologies, lies in providing client-focused Business Consulting and Re-Engineering of IT Services."
        />
        <meta
          name="keywords"
          content="microsoft dynamics 365, management reporting, power bi, web development, web dev, android development, native development, application development, application, hybrid development, hybrid, microsoft, dynamics, ax, 365, 2012, 2009, web, development, dev, app, native, android, flutter, react, reactjs, reactnative, power, bi, powerbi, management, reporting, expertise, domain knowledge, competence, efficiency, client satisfication"
        />
      </Helmet>
      <div className="whyChooseUsHead">
        <h1>Why Choose Us?</h1>
      </div>
      <div data-aos="fade-right" className="content">
        <div className="contentSections">
          <h2>Expertise</h2>
          <div>
            We are certified professionals with expertise in Research, Design,
            Development, and Delivery of high-end Technology Solutions has
            achieved the toughest of implementations in IT Services. Our core
            competency, developed through extensive product wide exposure to a
            variety of business processes, industries, and technologies, lies in
            providing client-focused Business Consulting and Re-Engineering of
            IT Services.
          </div>
        </div>

        <div data-aos="fade-left" className="contentSections">
          <h2>Domain knowledge</h2>
          <div>
            In-depth domain knowledge and practical understanding of the
            scenarios help us understand the pain areas more clearly. This leads
            to proactive measures to avoid user dependency in a major part of
            the study. Communication gaps are eliminated thus resulting in
            reaching closer to user expectations.
          </div>
        </div>

        <div data-aos="fade-right" className="contentSections">
          <h2>Competence</h2>
          <div>
            Our approach increasingly empowers client organizations during the
            implementation process to provide successful self-sufficiency after
            implementation. A full set of supporting implementation tools
            provide both the consulting team and the client team clarity and
            common understanding of the project status and next steps throughout
            the implementation process.
          </div>
        </div>

        <div data-aos="fade-left" className="contentSections">
          <h2>Efficiency</h2>
          <div>
            We provide a clear path through the implementation, leading to the
            successful use of the system. We start with a clear concept of how
            you can accomplish your business activities and objectives in
            Dynamics 365 F&O. Phases of training, system setup, testing, more
            testing, and a smooth go-live follow on the implementation path. If
            new functionality is required to achieve your objectives, accomplish
            your tasks, or give your company a competitive advantage, we ensure
            that your modifications are properly designed and programmed to
            enhance your system.
          </div>
        </div>

        <div data-aos="fade-right" className="contentSections">
          <h2>Client satisfaction</h2>
          <div>
            Last but not the least Client satisfaction is our top priority & to
            make implementation success is our personal goal as it leads to a
            win-win situation in a project involving high cost and efforts.
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
