import React, { useEffect, useState, useRef } from "react";
import "./contactUs.css";
import Aos from "aos";
import "aos/dist/aos.css";

import { useDispatch } from "react-redux";
import { setNavColor } from "../../redux/actions";
import CustButton from "../../components/button/custButton";
import PopupCust from "reactjs-popup";

import ScrollDownGif from "../../graphics/scroll.gif";

//SEO
import { Helmet } from "react-helmet";

//loading
import LoadingOverlay from "react-loading-overlay";

//map
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";

const ContactUs = () => {
  const [isLoadingActive, setisLoadingActive] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const DEFAULT_LANGITUDE = 19.1930512;
  const DEFAULT_LATITUDE = 72.9602963;

  const position = [DEFAULT_LANGITUDE, DEFAULT_LATITUDE];

  const dispatch = useDispatch();
  useEffect(() => {
    Aos.init({ duration: 1000 });
    dispatch(setNavColor(3));
  });
  function isValidEmailAddress(address) {
    return !!address.match(/.+@.+/);
  }
  const submitMessage = async () => {
    setisLoadingActive(true);
    if (
      email.length > 0 &&
      name.length > 0 &&
      subject.length > 0 &&
      message.length > 0
    ) {
      if (isValidEmailAddress(email) === false) {
        setOpenPopup(false);
        setisLoadingActive(false);
        setError("Please enter a valid email address");
      } else {
        setError("");
        await fetch("https://manesapi-290613.el.r.appspot.com/sendMail", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: email,
            name: name,
            subject: subject,
            message: message,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            console.log("response status: ", response.status);
            if (response.status === "success") {
              setisLoadingActive(false);
              setOpenPopup(true);
            } else {
              setOpenPopup(false);
              setisLoadingActive(false);
              setError("Something went wrong, please try again");
            }
          })
          .catch((e) => {
            setisLoadingActive(false);
            setOpenPopup(false);
            setError("Something went wrong, please try again");
            console.log("fetch error: ", e);
          });
      }
    } else {
      setOpenPopup(false);
      setisLoadingActive(false);
      setError("please don't leave the fields empty");
    }
  };
  function checkError() {}
  const closeModal = () => {
    let elementsToClear = document.getElementsByClassName(
      "ContactUsClearables"
    );
    for (let i = 0; i < elementsToClear.length; i++) {
      elementsToClear[i].value = "";
    }
    setOpenPopup(false);
  };
  return (
    <LoadingOverlay active={isLoadingActive} spinner>
      <div className="contactUsContainer">
        <Helmet>
          <title>Contact us</title>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Contact us for more information about our services or any other queries"
          />
          <meta
            name="keywords"
            content="microsoft dynamics 365,management reporting,power bi,web development,web dev, android development,native development,application development, application, hybrid development, hybrid, microsoft,dynamics,ax,365,2012,2009,web,development,dev,app,native,android,flutter,react,reactjs,reactnative,power,bi,powerbi,management,reporting,contact,us,get,in,touch, know us"
          />
        </Helmet>
        <PopupCust open={openPopup} closeOnDocumentClick onClose={closeModal}>
          <div className="modal">
            <div>
              <h1>Great! we've received your message</h1>
            </div>
            <div>
              {" "}
              <a className="close" onClick={closeModal}>
                Ok
              </a>
            </div>
          </div>
        </PopupCust>
        <div className="contactUsContents">
          <div className="getInTouchContent">
            <h1>Get in touch</h1>
            <div>
              Feel free to contact us about our services or any questions you
              may have. We will get back to you as soon as possible.
            </div>
          </div>
          <div>
            <div className="contactUsForm">
              <input
                className="ContactUsClearables"
                onChange={(event) => {
                  setEmail(event.target.value);
                  checkError();
                }}
                placeholder="Email"
              ></input>
              <input
                className="ContactUsClearables"
                onChange={(event) => {
                  setName(event.target.value);
                  checkError();
                }}
                placeholder="Name"
              ></input>
              <input
                className="ContactUsClearables"
                onChange={(event) => {
                  setSubject(event.target.value);
                  checkError();
                }}
                placeholder="Subject"
              ></input>
              <textarea
                onChange={(event) => {
                  setMessage(event.target.value);
                  checkError();
                }}
                placeholder="Your message"
                className="contectUsMessage ContactUsClearables"
              ></textarea>
              <text className="contactUsError">{error}</text>
              <div className="contactUsSubmitButton" onClick={submitMessage}>
                Submit
              </div>
            </div>
          </div>
        </div>
        <div className="scrollDownContactGif">
          <img alt="Scroll DOwn" src={ScrollDownGif}></img>
        </div>
      </div>

      <Map
        attributionControl={false}
        id="map"
        className="map"
        center={position}
        zoom={16}
        scrollWheelZoom={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker className="marker" position={position}>
          <Popup className="mapPopup">
            36, Ameya, Shreesh CHS, Hajuri Road, Thane W. 400604 MH
          </Popup>
        </Marker>
      </Map>
    </LoadingOverlay>
  );
};

export default ContactUs;
